const loan_mamla_file_perms = {
    menu: "mamla_container.loan_mamla_menu",
    add: "mamla_container.add_loanmamlafile",
    list: "mamla_container.view_loanmamlafile",
    zone_wise_list: "mamla_container.view_zone_wise_files",
    circle_wise_list: "mamla_container.view_circle_wise_files",
    branch_wise_list: "mamla_container.view_branch_wise_files",
    own_zone_list: "mamla_container.view_own_zone_files",
    own_circle_list: "mamla_container.view_own_circle_files",
    all_file_list: "mamla_container.view_all_files",

}

export default loan_mamla_file_perms;