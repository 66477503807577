<template>
  <v-container>
    <MamlaList v-if="show_own_branch"
               table_title="Your Branch Mamla Files"
               :list_url="loan_file_urls.loan_mamla.list"
               key="show_own_branch"
    />
    <MamlaList v-if="show_zone_wise"
               table_title="Zone Mamla Files"
               :list_url="loan_file_urls.loan_mamla.zone_wise.replace('{office_id}', office_id.id)"
               key="show_zone_wise"
               @select_another_office="handle_show_search_form"
               show_search_form_btn="true"
    />
    <MamlaList v-if="show_circle_wise"
               table_title="Circle Mamla Files"
               :list_url="loan_file_urls.loan_mamla.circle_wise.replace('{office_id}', office_id.id)"
               key="show_circle_wise"
               @select_another_office="handle_show_search_form"
               show_search_form_btn="true"
    />
    <MamlaList v-if="show_own_zone"
               table_title="Your Zone Mamla Files"
               :list_url="loan_file_urls.loan_mamla.own_zone"
               key="show_own_zone"
    />
    <MamlaList v-if="show_own_circle"
               table_title="Your Circle Mamla Files"
               :list_url="loan_file_urls.loan_mamla.own_circle"
               key="show_own_circle"
    />
    <MamlaList v-if="show_all_file"
               table_title="All File"
               :list_url="loan_file_urls.loan_mamla.all_files"
               key="show_all_file"
    />
    <search_form
        v-if="search_form"
        :office_selection_label="search_form_office_selection_label"
        :office_url="search_form_office_list_url"
        :key="search_form_office_selection_label"
        :title="search_form_title"
        @office_value="handle_office_value"
    />
  </v-container>
</template>

<script>
import MamlaList from "@/components/mamla/loan_mamla/MamlaList";
import loan_mamla_file_perms from "@/constants/permissions/loan_mamla_file_perms";
import loan_mamla_urls from "@/constants/mamla/loan_mamla/loan_mamla";
import search_form from "@/components/common_components/search_form.vue";
import {user_urls} from "@/constants/user_urls";

export default {
  name: "LoanMamlaList",
  components: {MamlaList, search_form},
  computed: {
    show_own_branch() {
      return this.$route.name === 'LoanMamlaList' && this.$store.getters.permissionCheckers(loan_mamla_file_perms.list)
    },
    show_zone_wise() {
      if (this.$route.name === 'LoanFileListCircleWise') {
        this.route_change()
      }
      return this.$route.name === 'LoanFileListZoneWise' && this.$store.getters.permissionCheckers(loan_mamla_file_perms.zone_wise_list) && this.office_id
    },
    show_circle_wise() {
      if (this.$route.name === 'LoanFileListZoneWise') {
        this.route_change()
      }
      return this.$route.name === 'LoanFileListCircleWise' && this.$store.getters.permissionCheckers(loan_mamla_file_perms.circle_wise_list) && this.office_id
    },
    show_own_zone() {
      if (this.$route.name === 'LoanFileListOwnZone') {
        this.route_change()
      }
      return this.$route.name === 'LoanFileListOwnZone' && this.$store.getters.permissionCheckers(loan_mamla_file_perms.own_zone_list)
    },
    show_own_circle() {
      if (this.$route.name === 'LoanFileListOwnCircle') {
        this.route_change()
      }
      return this.$route.name === 'LoanFileListOwnCircle' && this.$store.getters.permissionCheckers(loan_mamla_file_perms.own_circle_list)
    },
    show_all_file() {
      if (this.$route.name === 'AllFileList') {
        this.route_change()
      }
      return this.$route.name === 'AllFileList' && this.$store.getters.permissionCheckers(loan_mamla_file_perms.all_file_list)
    },
    loan_file_urls() {
      return loan_mamla_urls
    },
    search_form() {
      let perm = this.$route.name === 'LoanFileListZoneWise' || this.$route.name === 'LoanFileListCircleWise' || this.$route.name === 'LoanFileListBranchWise'
      perm = perm && !this.office_id
      return perm
    },
    office_urls() {
      return user_urls.office
    },
    search_form_office_selection_label() {
      if (this.$route.name === 'LoanFileListZoneWise') {
        return "Select a Zone"
      } else if (this.$route.name === 'LoanFileListCircleWise') {
        return "Select a Circle"
      } else if (this.$route.name === 'LoanFileListBranchWise') {
        return "Select a Branch"
      } else {
        return null
      }
    },
    search_form_title() {
      if (this.$route.name === 'LoanFileListZoneWise') {
        return "Search by Zone"
      } else if (this.$route.name === 'LoanFileListCircleWise') {
        return "Search by Circle"
      } else if (this.$route.name === 'LoanFileListBranchWise') {
        return "Search by Branch"
      } else {
        return null
      }
    },
    search_form_office_list_url() {
      if (this.$route.name === 'LoanFileListZoneWise') {
        return user_urls.office.zone.list
      } else if (this.$route.name === 'LoanFileListCircleWise') {
        return user_urls.office.circle.list
      } else if (this.$route.name === 'LoanFileListBranchWise') {
        return user_urls.office.branch.list
      } else {
        return null
      }
    },
  },
  data: () => ({
    office_id: null,
  }),
  methods: {
    handle_office_value(value) {
      this.office_id = value
    },
    handle_show_search_form(value) {
      this.office_id = value
    },
    route_change() {
      this.office_id = null
    }
  },
  mounted: () => {
    document.title = 'Agrani Bank Legal Suit System | Loan Mamla List'
    window.scrollTo(0, 0);
  },
  // watch: {
  //   route_name: {
  //     immediate: true,
  //     deep: true,
  //     handler(newValue, oldValue) {
  //       if (newValue && this.dialog) {
  //         if (newValue !== oldValue) {
  //           this.office_id = null
  //         }
  //       }
  //     }
  //   }
  // }
}
</script>

<style scoped>

</style>